<template>
  <div>
    <label v-if="label" class="label">{{ label }}</label>
    <autocomplete
      ref="autocomplate"
      @focusout="isNotEmpety(true)"
      @focus="isNotEmpety(false)"
      class="custom-autocomplate"
      :auto-select="true"
      :default-value="defaultValue"
      :search="search"
      @submit="handleSubmit"
      :get-result-value="getResultValue"
      :placeholder="placeholder"
      :aria-label="placeholder"
    >
      <template #result="data">
        <slot name="content" :data="data"></slot>
      </template>
    </autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    dataUrl: String,
    placeholder: String,
    label: String,
    defaultValue: {
      default: '',
      type: String
    },
    isRequired: Boolean,
  },
  data: () => ({
    autoselect: false,
    empetyValue: false,
    data: null,
  }),
  methods: {
    setDefaultValue: function(val) {
      this.defaultValue = val
    },
    isNotEmpety: function(out) {
      if (!this.isRequired) return;
      if (out) {
        const check = this.data
          ? this.data.name.match(new RegExp(this.$refs.autocomplate.value))
          : false;

        if (!check) {
          setTimeout(() => {
            if (!check)
              this.$refs.autocomplate.$el.classList.add(
                "custom-autocomplate-invalid"
              );
          }, 100);
        }
        this.$emit("isEmpety", check);
      } else {
        const cls = Array.from(this.$refs.autocomplate.$el.classList);
        if (cls.includes("custom-autocomplate-invalid")) {
          this.$refs.autocomplate.$el.classList.remove(
            "custom-autocomplate-invalid"
          );
        }
      }
    },
    search: function(input) {
      if (input.length < 1) {
        this.$http.get(this.dataUrl).then((resp) => {
          return resp.data.data;
        });
      }
      return this.$http.get(this.dataUrl + input).then((resp) => {
        return resp.data.data;
      });
    },
    getResultValue: function(result) {
      return result.name;
    },
    handleSubmit: function(result) {
      this.data = result;
      console.log(result)
      return this.$emit("getBackData", result);
    },
  },
  created: function() {
    if (this.defaultValue) this.autoselect = true;
  },
	mounted: function() {
	}
};
</script>

import BaseModel from "@/models/BaseModel.js";

export default class Airport extends BaseModel {
  fields = {
    name: {
      type: "text",
      rules: "required",
    },
    price: {
      type: "number",
      label: "harga",
    },
    regency_id: {
      type: "enum",
      label: "Kota",
      rules: "required",
      options: {},
    },
    is_active: {
      type: "enum",
      label: "Status",
      rules: "required",
      options: {
        0: "Tidak Aktif",
        1: "Aktif",
      },
    },
  };
  endpoint = process.env.VUE_APP_API_URL + "airport";
}

import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Regency extends BaseModel {
    fields =  {
      'name': {
        type: 'text',
        rules: 'required'
      }
    }
    endpoint = process.env.VUE_APP_API_URL + 'regencies'
}